































import { Component, Vue, Prop } from 'vue-property-decorator';
import { Timeline } from '@/types/cms/components';

/**
 * CMS timeline component
 */
@Component
export default class CMSComponentTimeline extends Vue {
    // prop with timeline data
    @Prop({ type: Object as () => Timeline, required: true }) readonly timelineData: Timeline | undefined;
}
